import React from "react"
import Layout from "@components/layout"
import Paragraph from "@components/elements/paragraph"
import Heading from "@components/elements/heading"
import Div from "@components/elements/div"
import Button from "@components/elements/button"
import Grid from "@components/elements/grid"
import { Logo } from "@components/common/logo"
import Anchor from "@components/elements/anchor"
import Flex from "@components/elements/flex"
import { SocialProofSection } from "@sections/home/social-proof"
import styled, { useTheme } from "styled-components"
import { HighGradient, MidGradient } from "@components/common/gradient"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import { motion } from "framer-motion"
import { ServiceLibraryAnimation } from "@components/animated/service-library"
import { NeutralCTA } from "@components/cta/eop/neutral"
import media from "../styles/media"
import { LogoCarousel } from "@components/brand/logo-carousel"
import Html from "@components/html"
import { Carousel } from "@components/image/carousel"
import { useNeutralGradient } from "@hooks/use-neutral-gradient"

const ProductWrapper = styled.div`
  @media only screen and (min-width: 1400px) {
    padding-bottom: 4rem;
    background: linear-gradient(
      ${({ theme }) => theme.color.indigo700},
      #081a2a
    );
  }
`

const ProductContainer = styled.div`
  @media only screen and (min-width: 1400px) {
    margin: 0 auto;
    max-width: 1400px;
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.shadow.glassy};
  }
`

const Wave1 = styled.svg`
  position: absolute;
  top: -14rem;
  left: 26%;
  mix-blend-mode: color-dodge;
  opacity: 0.9;
  ${media.sm`
    display: none;
  `}
`

const Wave2 = styled.svg`
  position: absolute;
  top: -11rem;
  right: 34%;
  filter: drop-shadow(40px 34px 40px rgba(0, 0, 0, 0.5));
  opacity: 0.9;
  ${media.sm`
    display: none;
  `}
`

const pathVariants = {
  rest: {
    opacity: 0.8,
    d: "M1684 330.5C1887.89 754.85 1616.14 368.101 1209 409.71C818.108 449.657 761.836 1206.64 224.838 531.991C-312.16 -142.66 497.363 -79.9934 1176.62 198.574C1520.03 61.7948 1480.11 -93.8503 1684 330.5Z",
  },
  wave: {
    opacity: 1,
    d: "M1628 298.453C1831.89 722.804 1617.86 295.277 1152.99 377.663C860.5 429.5 692.061 1320 209 615.134C-274.061 -89.7319 441.364 -112.04 1120.62 166.528C1464.03 29.7482 1424.11 -125.897 1628 298.453Z",
    transition: {
      duration: 36,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const pathVariants2 = {
  rest: {
    opacity: 1,
    d: "M71.4927 229.502C367.498 247.501 778.781 187.224 975.656 386.205C1122.8 534.917 1345.35 957.218 1632.74 441.37C1920.14 -74.4774 1446.16 50.2199 1064.46 14.276C461.451 -42.5076 -224.513 211.504 71.4927 229.502Z",
  },
  wave: {
    opacity: 0.8,
    d: "M71.4927 230.356C367.498 248.354 771.5 84.0003 975.657 387.058C1179.81 690.116 1324.48 955.447 1632.74 442.223C1941 -71 1446.17 51.073 1064.46 15.1291C461.451 -41.6545 -224.513 212.357 71.4927 230.356Z",
    transition: {
      duration: 36,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const lineVariants = {
  rest: {
    d: "M1 184.348C367.5 162.072 212 744.348 671 744.348C907 744.348 570.088 32.4516 773.835 0.735167",
  },
  wave: {
    d: "M1 184.348C486.5 250 212 744.348 671 744.348C907 744.348 570.088 32.4516 773.835 0.735167",
    transition: {
      duration: 16,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const lineVariants2 = {
  rest: {
    d: "M680.148 397.523C771.716 575.678 1049.84 968.536 1337.23 452.689C1624.63 -63.1589 1190.93 -6.17422 768.952 25.5946C-837 146.5 552.393 148.964 680.148 397.523Z",
  },
  wave: {
    d: "M577.349 2.88379C369.458 -47.7085 351.5 758.5 110.779 702.622C-129.941 646.744 149.98 23.3888 0.319105 2.88387",
    transition: {
      duration: 16,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const iconVariant1 = {
  rest: {
    y: 0,
  },
  wave: {
    y: 50,
    transition: {
      duration: 10,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const iconVariant2 = {
  rest: {
    y: 0,
  },
  wave: {
    y: 50,
    transition: {
      delay: 1,
      duration: 10,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const iconVariant3 = {
  rest: {
    y: 0,
  },
  wave: {
    y: 70,
    transition: {
      delay: 2,
      duration: 10,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const iconVariant4 = {
  rest: {
    y: 0,
  },
  wave: {
    y: 60,
    transition: {
      delay: 3,
      duration: 10,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "reverse",
    },
  },
}

const ImageWrapper = styled(Div)`
  ${media.md_up`
    height: 30vh;
    min-height: 250px;
    max-height: 380px;
  `}
`

const ProductGrid = styled(Grid)`
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

const TextWrapper = styled(Flex)`
  ${media.sm_up`
    align-items: center;
    text-align: center;
  `}
`

const HomePage = ({ data }) => {
  const { color, gradient } = useTheme()
  const images = processImages(data.images.edges)
  const icons = processImages(data.icons.edges)
  const lp = data.powermetricsLp

  const items = [{
    image: lp.grid_image_1,
    title: lp.grid_title_1,
    description: lp.grid_description_1,
  }, {
    image: lp.grid_image_2,
    title: lp.grid_title_2,
    description: lp.grid_description_2,
  }, {
    image: lp.grid_image_3,
    title: lp.grid_title_3,
    description: lp.grid_description_3,
  }]

  const { gradient: imgGradient } = useNeutralGradient()

  return (
    <Layout
      fullWidth
      marginless
      login
      seo={lp.seo}
    >
      <Div
        position="relative"
        background={color.indigo700}
        maxWidth="100%"
        width="100%"
        overflow="hidden"
      >
        <Div
          left="0"
          right="0"
          maxWidth="1600px"
          position="absolute"
          width="100%"
          top="-8rem"
        >
          <HighGradient
            style={{ minWidth: "800px" }}
            top="18rem"
            src={data.gradient.cdn}
          />
          <Wave1
            width="1754"
            height="940"
            viewBox="0 0 1754 940"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <motion.path
                d="M1684 330.5C1887.89 754.85 1616.14 368.101 1209 409.71C818.108 449.657 761.836 1206.64 224.838 531.991C-312.16 -142.66 497.363 -79.9934 1176.62 198.574C1520.03 61.7948 1480.11 -93.8503 1684 330.5Z"
                animate="wave"
                variants={pathVariants}
                fill="url(#paint0_linear_274_8305)"
              />
            </g>
            <g
              style={{
                filter: "drop-shadow(0px 34px 0px #0F1124)",
                opacity: 0.5,
                mixBlendMode: "color-dodge",
              }}
            >
              <motion.path
                d="M1684 330.5C1887.89 754.85 1616.14 368.101 1209 409.71C818.108 449.657 761.836 1206.64 224.838 531.991C-312.16 -142.66 497.363 -79.9934 1176.62 198.574C1520.03 61.7948 1480.11 -93.8503 1684 330.5Z"
                animate="wave"
                variants={pathVariants}
                fill="url(#paint0_linear_274_8305)"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_274_8305"
                x1="889.678"
                y1="750.155"
                x2="1071.55"
                y2="479.313"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7E55B2" />
                <stop offset="1" stopColor="#38448A" stopOpacity="0" />
              </linearGradient>
            </defs>
          </Wave1>
          <Wave2
            width="1810"
            height="806"
            viewBox="0 0 1810 806"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              d="M71.4927 229.502C367.498 247.501 778.781 187.224 975.656 386.205C1122.8 534.917 1345.35 957.218 1632.74 441.37C1920.14 -74.4774 1446.16 50.2199 1064.46 14.276C461.451 -42.5076 -224.513 211.504 71.4927 229.502Z"
              animate="wave"
              variants={pathVariants2}
              fill="url(#paint0_linear_312_22661)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_312_22661"
                x1="565.221"
                y1="671.582"
                x2="197.02"
                y2="152.585"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#75E1DA" />
                <stop offset="1" stopColor="#38448A" stopOpacity="0" />
              </linearGradient>
            </defs>
          </Wave2>
          <Div
            position="absolute"
            width="100%"
            height="600px"
            top="11rem"
            background="linear-gradient(180deg, rgba(15, 17, 36, 0.00) 10%, #0F1124 70%)"
            style={{
              mask: "linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 10%, #000000 90%, rgba(0, 0, 0, 0) 100%)",
            }}
          ></Div>
          <Div hideOnSm>
            <svg
              width="774"
              height="756"
              viewBox="0 0 774 756"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ position: "absolute", top: "-6rem", right: "51%" }}
            >
              <g style={{ mixBlendMode: "plus-lighter" }}>
                <motion.path
                  animate="wave"
                  variants={lineVariants}
                  d="M1 184.348C367.5 162.072 212 744.348 671 744.348C907 744.348 570.088 32.4516 773.835 0.735167"
                  stroke="url(#paint0_linear_331_36301)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_331_36301"
                  x1="405"
                  y1="542.348"
                  x2="641.158"
                  y2="519.444"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#34AD78" />
                  <stop offset="1" stopColor="#424A94" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              width="578"
              height="719"
              viewBox="0 0 578 719"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: "absolute",
                top: "6rem",
                left: "56%",
              }}
            >
              <g style={{ mixBlendMode: "plus-lighter" }}>
                <motion.path
                  animate="wave"
                  variants={lineVariants2}
                  d="M577.5 2.88363C369.609 -47.7087 332.561 839.749 110.93 702.622C-92.9417 576.483 150.131 23.3887 0.469721 2.8837"
                  stroke="url(#paint0_linear_331_36304)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_331_36304"
                  x1="386.853"
                  y1="352.96"
                  x2="146.323"
                  y2="402.293"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#707EFA" />
                  <stop offset="1" stopColor="#424A94" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </Div>
          <Div hideOnMd position="relative" height="40px">
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant1}
              animate="wave"
              style={{ position: "absolute", top: "30rem", left: "12%" }}
              file={icons["b017da8b-6c31-47ff-a77a-9f75cf6fb353"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant2}
              animate="wave"
              style={{ position: "absolute", top: "34rem", left: "20%" }}
              file={icons["3efdd553-4448-4e9e-9e5f-dabf2647b45c"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant3}
              animate="wave"
              style={{ position: "absolute", top: "38rem", left: "15%" }}
              file={icons["3aa397c0-c54c-4c24-9d6a-fa2e7641dfe9"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant4}
              animate="wave"
              style={{ position: "absolute", top: "40rem", left: "23%" }}
              file={icons["9e4c6e11-0e74-45b6-8557-3f3291b6ec1f"]}
            />

            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant4}
              animate="wave"
              style={{ position: "absolute", top: "30rem", right: "12%" }}
              file={icons["944f1830-d5f3-42bd-9d97-247790e18cef"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant3}
              animate="wave"
              style={{ position: "absolute", top: "34rem", right: "20%" }}
              file={icons["40dc1991-0dde-461c-b6f6-0fe65f99779e"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant2}
              animate="wave"
              style={{ position: "absolute", top: "38rem", right: "15%" }}
              file={icons["cf9cfc3a-a202-416c-a1bf-d7aa15484c0f"]}
            />
            <Image
              width={40}
              height={40}
              as={motion.img}
              variants={iconVariant1}
              animate="wave"
              style={{ position: "absolute", top: "40rem", right: "23%" }}
              file={icons["e6486f9a-8339-451c-bea4-20d70118ed2d"]}
            />
          </Div>
        </Div>
        <TextWrapper
          gap="1rem"
          padding="10rem 1rem 6rem"
          paddingMd="8rem 1rem 3rem"
          position="relative"
          maxWidth="800px"
        >
          <Heading
            as="h1"
            color="white"
            style={{
              textShadow: "0px 4px 25px rgba(33, 38, 79, 0.50)",

              fontSize: "clamp(2rem, 3vw + 1.5rem, 4rem)",
            }}
          >
            {lp.header}
          </Heading>
          <Paragraph
            color="white"
            fontSize="1.5rem"
            lineHeight="160%"
            fontWeight={600}
            margin="0 0 1rem"
          >
            {lp.description}
          </Paragraph>
          <Button.Agnostic
            background={gradient.powerMetricsButtonAlt}
            boxShadow="0px 8px 16px rgba(75, 87, 197, 0.2), inset 0px -6px 14px rgba(255, 255, 255, 0.4)"
            color={color.indigo600}
            margin="0 0 5rem"
            marginMd="0 0 4rem"
            noticeText="No credit card required"
            noticeColor="white"
            width="max-content"
            data-cta-location="header"
          />
          <Paragraph color="white" fontWeight={600} lineHeight={1.6}>
            You’ll be in great company with over 1,600 other happy customers
          </Paragraph>
          <LogoCarousel />
        </TextWrapper>
      </Div>
      <Div overflow="hidden" position="relative" background={color.indigo700}>
        <Flex position="relative" zIndex={1} gap="1rem" textContainer>
          <Html
            styles={{
              paragraph: {
                color: "white",
              },
              heading: {
                color: "white",
              },
              link: {
                color: "white",
              },
              list: {
                color: "white",
              },
            }}
            parseElements
            html={lp.block_1}
            images={lp.block_1_images}
          />
        </Flex>
        <Carousel items={items} />
        <MidGradient src={imgGradient.cdn} />
        <Flex position="relative" zIndex={1} gap="1rem" margin="0 auto 8rem" marginMd="0 auto 6rem" marginSm="0 auto 4rem" textContainer>
          <Html
            styles={{
              paragraph: {
                color: "white",
              },
              heading: {
                color: "white",
              },
              link: {
                color: "white",
              },
              list: {
                color: "white",
              },
            }}
            parseElements
            html={lp.block_2}
            images={lp.block_2_images}
          />
        </Flex>
      </Div>
      <ProductWrapper>
        <ProductContainer>
          <ProductGrid columns="repeat(2, 1fr)">
            <Flex
              background="linear-gradient(180deg, #21264F 0%, #0F1124 100%)"
              padding="4rem"
              paddingMd="3rem"
              paddingSm="2rem"
              gap="1rem"
              alignItems="flex-start"
            >
              <Logo.PowerMetrics />
              <Paragraph
                fontSize="1.5rem"
                mobileScale={0.7}
                color={color.indigo300}
              >
                Top choice for the modern analytics stack
              </Paragraph>
              <ImageWrapper
                margin="1rem 0 1rem -4rem"
                marginSm="0.5rem 0"
                background="#B0B8FC"
                borderRadius="0 0.5rem 0.5rem 0"
                borderRadiusMd="0.5rem"
                overflow="hidden"
              >
                <Image
                  style={{ maxHeight: "unset" }}
                  file={images["f45e2995-4338-48b6-bd26-94832e1d2739"]}
                />
              </ImageWrapper>
              <Heading as="h2" color="white">
                Metric catalog & analytics
              </Heading>
              <Paragraph
                fontSize="1.3rem"
                lineHeight="1.5"
                color="white"
                margin="0 0 1rem"
                marginSm="0 0 0.5rem"
              >
                Put data analysis and dashboard creation into the hands of
                business users with curated metrics, governed by the data team.
              </Paragraph>
              <Flex
                margin="auto 0 0"
                flexFlow="row"
                gap="1rem"
                alignItems="center"
              >
                <Button.PowerMetrics
                  force
                  data-cta-location="product-selector"
                  notice={false}
                >
                  Get Started
                </Button.PowerMetrics>
                <Anchor
                  style={{ alignItems: "center", display: "flex" }}
                  color="white"
                  arrow
                  link="/powermetrics"
                >
                  Learn more
                </Anchor>
              </Flex>
            </Flex>
            <Flex
              background="linear-gradient(180deg, #013950 0%, #012330 100%)"
              padding="4rem"
              paddingMd="3rem"
              paddingSm="2rem"
              gap="1rem"
              alignItems="flex-start"
              overflow='hidden'
            >
              <Logo.Klips />
              <Paragraph
                color={color.blue300}
                mobileScale={0.7}
                fontSize="1.5rem"
              >
                Best for small businesses
              </Paragraph>
              <ImageWrapper
                margin="1rem -4rem 1rem 0"
                marginSm="0.5rem 0"
                background="#9EE6FA"
                borderRadius="0.5rem 0 0 0.5rem"
                borderRadiusMd="0.5rem"
                overflow="hidden"
              >
                <Image
                  style={{ maxHeight: "unset" }}
                  file={images["2bc66d7b-8bc4-47ce-ae7c-e02421053eff"]}
                />
              </ImageWrapper>
              <Heading as="h2" color="white">
                Dashboards & reports
              </Heading>
              <Paragraph
                fontSize="1.3rem"
                lineHeight="1.5"
                color="white"
                margin="0 0 1rem"
                marginSm="0 0 0.5rem"
              >
                Build and manage highly customizable dashboards and reports for
                your team and clients.
              </Paragraph>
              <Flex
                margin="auto 0 0"
                flexFlow="row"
                gap="1rem"
                alignItems="center"
              >
                <Button.Klips
                  force
                  data-cta-location="product-selector"
                  notice={false}
                  noticeColor="white"
                >
                  Get Started
                </Button.Klips>
                <Anchor
                  style={{ alignItems: "center", display: "flex" }}
                  color="white"
                  arrow
                  link="/klips"
                >
                  Learn more
                </Anchor>
              </Flex>
            </Flex>
          </ProductGrid>
        </ProductContainer>
      </ProductWrapper>
      <Div margin="8rem auto" marginMd="6rem auto" marginSm="4rem auto">
        <SocialProofSection />
      </Div>
      <Div
        container
        position="relative"
        overflow="hidden"
        margin="0 auto 8rem"
        marginMd="0 auto 6rem"
        marginSm="0 auto 4rem"
        maxWidth="100%"
      >
        <Heading
          style={{ fontSize: "clamp(2rem, 3vw + 1rem, 4rem)" }}
          margin="0 0 3rem"
          marginSm="0 0 2rem"
          as="h2"
          center
        >
          100+ Technology Partners
        </Heading>
        <ServiceLibraryAnimation />
      </Div>
      <NeutralCTA />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HomePage

export const pageQuery = graphql`
  query PmLPQuery($id: String!) {
    powermetricsLp(id: { eq: $id }) {
      block_1
      block_1_images {
        directus_id
        cdn
        id
        placeholder
        title
      }
      block_2
      block_2_images {
        directus_id
        cdn
        id
        placeholder
        title
      }
      description
      grid_description_1
      grid_description_2
      grid_description_3
      grid_image_1 {
        directus_id
        cdn
        id
        placeholder
        title
      }
      grid_image_2 {
        directus_id
        cdn
        id
        placeholder
        title
      }
      grid_image_3 {
        directus_id
        cdn
        id
        placeholder
        title
      }
      grid_title_1
      grid_title_2
      grid_title_3
      header
      image_1 {
        directus_id
        cdn
        id
        placeholder
        title
      }
      seo {
        ...seoData
      }
      video_body
      video_body_images {
        directus_id
        cdn
        id
        placeholder
        title
      }
    }
    gradient: directusFiles(
      directus_id: { eq: "56ded7c2-7a1c-4c29-9a69-5ce23a09f1af" }
    ) {
      id
      cdn
      title
    }
    icons: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "b017da8b-6c31-47ff-a77a-9f75cf6fb353"
            "3efdd553-4448-4e9e-9e5f-dabf2647b45c"
            "3aa397c0-c54c-4c24-9d6a-fa2e7641dfe9"
            "9e4c6e11-0e74-45b6-8557-3f3291b6ec1f"
            "944f1830-d5f3-42bd-9d97-247790e18cef"
            "cf9cfc3a-a202-416c-a1bf-d7aa15484c0f"
            "40dc1991-0dde-461c-b6f6-0fe65f99779e"
            "e6486f9a-8339-451c-bea4-20d70118ed2d"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "f45e2995-4338-48b6-bd26-94832e1d2739"
            "2bc66d7b-8bc4-47ce-ae7c-e02421053eff"
          ]
        }
      }
    ) {
      edges {
        node {
          cdn
          title
          placeholder
          directus_id
        }
      }
    }
  }
`
