import React, { useState } from "react"
import Flex from "@components/elements/flex"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"
import Container from "@components/layout/container"
import { graphql, useStaticQuery } from "gatsby"
import Image from "@components/image"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import theme from "../../styles/theme"
import media from "../../styles/media"
import { MotionDiv, MotionFlex, MotionGrid } from "@components/elements/motion"

const GlassyDiv = styled(MotionDiv)`
  background: radial-gradient(
    90.16% 143.01% at 15.32% 21.04%,
    rgba(165, 239, 255, 0.2) 0%,
    rgba(236, 183, 210, 0.0447917) 77.08%,
    rgba(70, 144, 213, 0) 100%
  );
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem 3rem;
  border-radius: 16px;
  box-shadow: ${theme.shadow.glassy};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 2px solid rgba(255, 255, 255, 0.59);
  ${media.sm`
    ${props =>
      props.reverse &&
      css`
        order: -1;
      `}
  `}
`

const Item = ({ item, id, featured, setFeatured }) => {
  return (
    <Flex onClick={() => setFeatured(id)} gap="2rem">
      <GlassyDiv
        initial={{ opacity: 0.5 }}
        animate={{ opacity: featured === id ? 1 : 0.5 }}
      >
        <Image
          style={{
            borderRadius: "1rem",
            margin: 0,
            height: 380,
            width: "auto",
            pointerEvents: "none",
          }}
          radius={1}
          file={item.image}
        />
      </GlassyDiv>
      <MotionFlex
        initial={{ opacity: 0 }}
        animate={{ opacity: featured === id ? 1 : 0 }}
      >
        <Heading margin="0 0 1rem" color="white" as="h4">
          {item.title}
        </Heading>
        <Paragraph color={"white"} margin="0 0 1rem" lineHeight="1.5">
          {item.description}
        </Paragraph>
      </MotionFlex>
    </Flex>
  )
}

Item.propTypes = {
  item: PropTypes.object,
  id: PropTypes.number,
  featured: PropTypes.number,
  setFeatured: PropTypes.func,
}

export const Carousel = ({ items }) => {
  const data = useStaticQuery(
    graphql`
      query CarouselQuery {
        icon: directusFiles(
          directus_id: { eq: "b715b87f-eb35-423a-b1f6-b41db3c55e0b" }
        ) {
          id
          cdn
          title
          placeholder
          directus_id
        }
      }
    `
  )

  return (
    <Div position="relative" overflow="hidden">
      <Div hideOnMd position="relative" overflow="hidden">
        <Container style={{ zIndex: "10", position: "relative" }}>
          <Flex padding="8rem 0" gap="2rem">
            <Div position="relative">
              <Rotation icon={data.icon} items={items} />
            </Div>
          </Flex>
        </Container>
      </Div>
      <Div showOnMd>
        <Container style={{ zIndex: "10", position: "relative" }}>
          <Flex padding="4rem 0" gap="2rem">
            {items.map((item, i) => (
              <Flex gap="2rem" key={`mobile-carousel-${i}`} position="relative">
                  <Image
                    radius={1}
                    file={item.image}
                  />
                <Flex>
                  <Heading margin="0 0 1rem" color="white" as="h4">
                    {item.title}
                  </Heading>
                  <Paragraph color={"white"} margin="0 0 1rem" lineHeight="1.5">
                    {item.description}
                  </Paragraph>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Container>
      </Div>
    </Div>
  )
}

Carousel.propTypes = {
  items: PropTypes.array,
}

const Rotation = ({ icon, items }) => {
  const [featured, setFeatured] = useState(0)

  return (
    <Div position="relative">
      <Image
        radius={4}
        height={60}
        width={60}
        file={icon}
        style={{
          position: "absolute",
          left: "-7%",
          transform: "scaleX(-1)",
          top: "35%",
          zIndex: 100,
          cursor: "pointer",
          opacity: featured > 1 ? 1 : 0,
          transition: "opacity 0.5s",
          pointerEvents: featured > 4 ? "auto" : "none",
        }}
        onClick={() => featured > 0 && setFeatured(0)}
        borderStyle={{ boxShadow: theme.shadow.default }}
      />
      <Image
        radius={4}
        height={60}
        width={60}
        file={icon}
        style={{
          position: "absolute",
          right: "-7%",
          top: "35%",
          zIndex: 100,
          cursor: "pointer",
          opacity: featured > 1 ? 0 : 1,
          transition: "opacity 0.5s",
          pointerEvents: featured > 4 ? "none" : "auto",
        }}
        onClick={() => featured < 5 && setFeatured(featured + 1)}
        borderStyle={{ boxShadow: theme.shadow.default }}
      />
      <MotionGrid
        animate={{
          x: featured * -600,
          transition: {},
        }}
        columns="repeat(3, 600px)"
        gap="72px 36px"
      >
        {items.map((item, i) => (
          <Item
            key={`template-${i}`}
            item={item}
            featured={featured}
            setFeatured={setFeatured}
            id={i}
          />
        ))}
      </MotionGrid>
    </Div>
  )
}

Rotation.propTypes = {
  icon: PropTypes.object,
  items: PropTypes.array,
}
